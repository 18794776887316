module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/theme/img-unilever-favicon.jpg"},
    },{
      plugin: require('../node_modules/@discoveryed/de-analytics/gatsby-browser.js'),
      options: {"plugins":[],"partnershipId":"59c02648-edee-426b-9fd5-71a40f159486"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
